import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import BlogTeaser from "../../components/BlogTeaser"

export default ({
  data: {
    allPrismicBlogpost: { edges },
  },
  pageContext: { locale },
}) => (
  <Layout locale={locale}>
    <SEO title="Blogs" />
    {edges.map(({ node }) => (
      <BlogTeaser key={node.uid} node={node} />
    ))}
  </Layout>
)

export const query = graphql`
  query getBlogTeasers($langcode: String!) {
    allPrismicBlogpost(filter: { lang: { eq: $langcode } }) {
      edges {
        node {
          ...BlogTeaser
        }
      }
    }
  }
`
